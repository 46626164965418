import API from '@/services/api'
import { relocateToLogin } from '@/helpers/navguard'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const nullConfig = {
  useZenoAuth: null,
  contextPath: null,
  cookieName: null
}

const state = {
  authenticated: false,
  authenticating: false,
  groupsWithRadioLinks: [],
  isUserWithMisconfiguredGroup: [],
  user: null,
  config: nullConfig
}

export const actions = {
  authenticate ({ dispatch, commit, getters }, { endThen, onError }) {
    API.authenticate().then(r => {
      commit('setUser', r.data)
      commit('setAuthenticated', true)
      commit('setAuthenticating', false)
      API.getGroupsWithRadioLinksByUserId(r.data.id).then(r2 => {
        commit('setgroupsWithRadioLinks', r2.data)
        API.isUserWithMisconfiguredGroup(r.data.id).then(r3 => {
          commit('isUserWithMisconfiguredGroup', r3.data)
        })
      })
      endThen()
    }).catch(onError)
  },
  routerAuthenticate ({ dispatch, commit, getters }, next) {
    dispatch('initConfig').then(() => {
      dispatch('authenticate', {
        endThen: next,
        onError () {
          commit('setAuthenticated', false)
          commit('setAuthenticating', true)
          if (!getters.isZenoAuthUsed) {
            next('/ui/login')
          }
        }
      })
    })
  },
  login ({ dispatch }, credentials) {
    return API.login(credentials).then(() => {
      dispatch('authenticate', {
        endThen () {},
        onError () {}
      })
    })
  },
  logout ({ commit }) {
    commit('setAuthenticated', false)
    API.logout().then(relocateToLogin)
  },
  initConfig ({ commit }) {
    return API.getConfig().then(r => {
      commit('setConfig', r.data)
    })
  },
  notAuthenticatingAnyMore ({ commit }) {
    commit('setAuthenticating', false)
  },
  secureLogin ({ commit, dispatch, getters }, next) {
    dispatch('initConfig').then(() => {
      if (getters.isZenoAuthUsed) {
        relocateToLogin()
      } else {
        next()
      }
    })
  }
}

export const mutations = {
  setUser (state, user) {
    state.user = Object.assign({}, {
      id: user.id,
      userName: user.userName,
      login: user.name,
      admin: user.admin
    })
  },
  setAuthenticated (state, authenticated) {
    state.authenticated = authenticated
  },
  setAuthenticating (state, authenticating) {
    state.authenticating = authenticating
  },
  setgroupsWithRadioLinks (state, groupsWithRadioLinks) {
    state.groupsWithRadioLinks = groupsWithRadioLinks
  },
  isUserWithMisconfiguredGroup (state, isUserWithMisconfiguredGroup) {
    state.isUserWithMisconfiguredGroup = isUserWithMisconfiguredGroup
  },
  setConfig (state, config) {
    state.config = config
  }
}

export const getters = {
  isZenoAuthUsed (state) {
    return state.config.useZenoAuth
  },
  getContextPath (state) {
    return state.config.contextPath
  },
  getCookieName (state) {
    return state.config.cookieName
  },
  isAuthenticating (state) {
    return state.authenticating
  },
  isConfigUnset (state) {
    return state.config === nullConfig
  }
}

export default new Vuex.Store({
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
})
